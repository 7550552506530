<div *ngIf="settings">

<app-login-nav></app-login-nav>

<ng-container>
  <div [ngStyle]="{'background-color':  settings.login_background_image &&
  settings.login_background_image.bgColor ? settings.login_background_image.bgColor : null, 'background-image': settings.login_background_image &&
  settings.login_background_image.path ? 'url('+  fileMapping.imageFolder + '/' +  settings.login_background_image.path + ')' : null, 'width': '100%', 'height': '100%', 'background-size': 'cover', 'position': 'fixed' }">

    <main class="container my-5 w-lg-75 w-xl-66" style=" opacity: 0.9;" >

      <!-- login-form -->
      <form [formGroup]="loginForm" *ngIf="!singleSignOnToken && !(loaderService.isLoading | async) && !(loaderService.isNavigating | async) && !isPasswordExpired" method="post" action="#" class="bg-light border-bottom border-primary py-5 px-4" style=" margin-left: auto; margin-right: auto;">
        <h1 class="text-dark mb-4 pl-4">Login</h1>
        <div class="mb-4">
          <label for="account" class="form-label" [innerHTML]="translationService.getTranslation('login', 'label_username')"></label>
          <input type="text" class="form-control placeholder-with-icon" id="account" name="account"
                 [placeholder]="translationService.getTranslation('login', 'label_username')" formControlName="username">
        </div>
        <div class="mb-4">
          <label for="password"  class="form-label" [innerHTML]="translationService.getTranslation('login', 'label_password')"></label>
          <input type="password" class="form-control placeholder-with-icon" id="password" name="password"
                 [placeholder]="translationService.getTranslation('login', 'label_password')" formControlName="password">
        </div>
        <div class="d-grid d-sm-block">
          <button (click)="login()"  [innerHTML]="translationService.getTranslation('login', 'button_sign_in')" type="submit"
                  class="btn btn-primary text-uppercase font-family-regular px-4" style="background-color: #FF0000;">

          </button>

        </div>
        <!-- <div class="mt-4 text-center text-sm-left">
          <a href="#" class="font-family-regular" [innerHTML]="translationService.getTranslation('login', 'problems')"></a>
        </div> -->
        <app-footer></app-footer>
      </form>
      <!-- /login-form -->


      <!-- change-password-form -->
      <form [formGroup]="changePasswordForm" *ngIf="!singleSignOnToken && !(loaderService.isLoading | async) && !(loaderService.isNavigating | async) && isPasswordExpired" method="post" action="#" class="bg-light border-bottom border-primary py-3 px-4 change-password-form" style=" margin-left: auto; margin-right: auto;">
        <div class="mb-4">
          <label for="account" class="form-label" [innerHTML]="translationService.getTranslation('login', 'label_username')"></label>
          <input type="text" class="form-control placeholder-with-icon" id="account" name="account"
                 [placeholder]="translationService.getTranslation('login', 'label_username')" formControlName="username">
        </div>
        <div class="mb-4">
          <label for="password"  class="form-label" [innerHTML]="translationService.getTranslation('login', 'label_password')"></label>
          <input type="password" class="form-control placeholder-with-icon" id="password" name="password"
                 [placeholder]="translationService.getTranslation('login', 'label_password')" formControlName="password">
        </div>
        <div class="mb-4">
          <label for="newPassword"  class="form-label" [innerHTML]="translationService.getTranslation('login', 'label_new_password')"></label>
          <input type="password" class="form-control placeholder-with-icon" id="newPassword" name="newPassword"
                 [placeholder]="translationService.getTranslation('login', 'label_new_password')" formControlName="newPassword">
        </div>
        <div class="mb-4">
          <label for="newPasswordConfirm"  class="form-label" [innerHTML]="translationService.getTranslation('login', 'label_new_password_confirm')"></label>
          <input type="password" class="form-control placeholder-with-icon" id="newPasswordConfirm" name="newPasswordConfirm"
                 [placeholder]="translationService.getTranslation('login', 'label_new_password_confirm')" formControlName="newPasswordConfirm">
          <p class="error-text" *ngIf="this.changePasswordForm.controls['newPasswordConfirm'].touched && this.changePasswordForm.controls['newPassword'].value !== this.changePasswordForm.controls['newPasswordConfirm'].value">The Password does not match.</p>
        </div>
        <div class="d-grid d-sm-block">
          <button (click)="login()" [disabled]="this.changePasswordForm.controls['newPassword'].value !== this.changePasswordForm.controls['newPasswordConfirm'].value" [innerHTML]="translationService.getTranslation('login', 'button_change_password')" type="submit"
                  class="btn btn-primary text-uppercase font-family-regular px-4">
          </button>
        </div>
        <app-footer></app-footer>
      </form>
      <!-- /change-password-form -->


    </main>


  </div>
</ng-container>
</div>
