import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {AuthService} from '../shared/services/auth.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DOCUMENT} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfigService} from '../shared/services/config.service';
import {HttpClient} from '@angular/common/http';
import {from, Observable, of} from 'rxjs';
import {ConfigEntry, FileMapping, FilterFile, Message, MessageTypeEnum, PitNameEnum, Settings} from '../pit/structure';
import {NavigationService} from '../shared/services/navigation.service';
import {LoginCredential} from '../shared/services/swagger/models/login-credential';
import {TranslationService} from '../shared/services/translation.service';
import {EntityService} from '../shared/services/swagger/services/entity.service';
import {PermissionService} from '../shared/services/permission.service';
import {RoutingProviderService} from '../pit/services/routing-provider.service';
import {UserService} from '../shared/services/swagger/services/user.service';
import {MessageStackService} from '../shared/services/message-stack.service';
import {FormDataService} from '../shared/services/form-data.service';
import {DataService} from '../shared/services/data.service';
import {CallbackService} from '../shared/services/callback.service';
import {catchError, finalize, map, mergeMap} from 'rxjs/operators';
import {SetUserRoleRequest} from '../shared/services/swagger/models/set-user-role-request';
import {SessionService} from '../shared/services/swagger/services/session.service';
import {SetAppIdRequest} from '../shared/services/swagger/models/set-app-id-request';
import {EntityCallFunctionRequest} from '../shared/services/swagger/models/entity-call-function-request';
import {UserRole} from '../shared/services/swagger/models/user-role';
import {ClientsService} from '../shared/services/swagger/services/clients.service';
import {SetClientArrayRequest} from '../shared/services/swagger/models/set-client-array-request';
import {SettingsComponent} from '../shared/components/dialogs/settings/settings.component';
import {Dialog} from '@syncfusion/ej2-angular-popups';
import {TwoFactorModalComponent} from './two-factor-modal/two-factor-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {ChangePassword} from '../shared/services/swagger/models';
import {NewCommand} from '@angular/cli/commands/new-impl';
import {LoaderService} from '../shared/services/loader.service';
import {settings} from 'cluster';
import {buttonObserver} from '@syncfusion/ej2-angular-buttons';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {
  isPasswordExpired: boolean = false;

  currentTheme = 'login bg-dark d-flex flex-column h-100';

  config: any[] = [];

  isCapsLockActive: boolean;

  singleSignOnToken: string;

  settings: Settings;

  fileMapping: FileMapping;

  loginForm: FormGroup = new FormGroup({
    username: new FormControl(),
    password: new FormControl(),

  })
  ;
  changePasswordForm: FormGroup = new FormGroup({
      username: new FormControl(Validators.required),
      password: new FormControl(Validators.required),
      newPassword: new FormControl(Validators.required),
      newPasswordConfirm: new FormControl(Validators.required),
    }
  );

  @HostListener('window:keyup', ['$event'])
  onCapsCheck(event: MouseEvent | KeyboardEvent): void {
    if (!!(event.getModifierState && event.getModifierState('CapsLock')) && !this.isCapsLockActive) {
      this.isCapsLockActive = true;

      const message: Message = {
        title: this.translationService.getTranslation('login', 'caps_lock_active_title'),
        text: this.translationService.getTranslation('login', 'caps_lock_active_text'),
        type: MessageTypeEnum.NOTICE
      };

      this.messageStackService.addMessage(message);
    } else {
      this.isCapsLockActive = !!(event.getModifierState && event.getModifierState('CapsLock'));
    }
  }


  constructor(@Inject(DOCUMENT) private document: Document,
              private router: Router,
              private httpClient: HttpClient,
              private authService: AuthService,
              private entityService: EntityService,
              private configService: ConfigService,
              private permissionService: PermissionService,
              private navigationService: NavigationService,
              public translationService: TranslationService,
              private userService: UserService,
              private dataService: DataService,
              private callBackService: CallbackService,
              private routingProviderService: RoutingProviderService,
              private messageStackService: MessageStackService,
              private formDataService: FormDataService,
              private activatedRoute: ActivatedRoute,
              private sessionService: SessionService,
              private clientService: ClientsService,
              public loaderService: LoaderService,
              private dialog: MatDialog) {
    this.document.body.classList.value = this.currentTheme;
    this.fileMapping = this.configService.getFileMapping();
  }


  ngOnInit(): void {
    // localStorage.setItem("session", null);
    this.loaderService.isNavigating.next(false);
    this.activatedRoute.data.subscribe(resolverResult => {
      this.routingProviderService.checkConnectivity().subscribe();

      // if (localStorage.getItem('defaultLang')) {
      //   console.log(JSON.parse(localStorage.getItem('defaultLang')));
      //
      // }


      this.settings = this.configService.getSettings();
      if (resolverResult.queryParamsData && resolverResult.queryParamsData['token']) {
        this.userService.getSingleSignOnToken(resolverResult.queryParamsData['token']).pipe(map((token: string) => {
          if (token && token !== '') {
            this.singleSignOnToken = token;
            this.loginForm.controls['username'].setValue(JSON.parse(this.singleSignOnToken));
            this.loginForm.controls['password'].setValue('');
            this.login();
          }
        })).subscribe();
      }
      if (resolverResult.queryParamsData && resolverResult.queryParamsData['user']) {
        let client: number;
        let scope: number;
        this.singleSignOnToken = resolverResult.queryParamsData['user'];
        this.loginForm.controls['username'].setValue(this.singleSignOnToken);
        this.loginForm.controls['password'].setValue(resolverResult.queryParamsData['pswd']);
        this.login();
      }

      if (this.configService.getSettings().auto_login) {
        this.userService.autoLogin().subscribe((res: string[]) => {
          this.loginForm.controls['username'].setValue(res['username']);
          this.loginForm.controls['password'].setValue(res['password']);
          this.login();
        });
      }

      if (resolverResult.queryParamsData && resolverResult.queryParamsData.view) {
        const sessionId: string = localStorage.getItem('sessionId');
        if (sessionId) {
          this.sessionService.getSession().pipe(mergeMap((res: any) => {
            return this.authService.getUserRoleAndCheckPermissionsObs();
          })).subscribe(() => {
          }, error => {
          });
        }
      }

      if (localStorage.getItem('session')) {
        this.login();

      }

    });
  }


  login(twoFactorCode: string = null): void {
    this.messageStackService.messages = [];
    this.messageStackService.messagesHistory = [];

    this.routingProviderService.isSessionTimeout = false;


    const countryCode: string = this.authService.defaultLang.countryCode;
    const languageName: string = this.authService.defaultLang.languageName;
    const regionName: string = this.authService.defaultLang.regionName;
    let userName: string;
    let password: string;
    let newPassword: string;
    let metaDataMode = false;

    if (this.isPasswordExpired) {
      const currentPassword: string = this.changePasswordForm.controls['password'].value;
      newPassword = this.changePasswordForm.controls['newPassword'].value;
      const newPasswordConfirm: string = this.changePasswordForm.controls['newPasswordConfirm'].value;

      if (newPassword !== newPasswordConfirm) {
        return;
      }

      this.isPasswordExpired = false;
      userName = this.changePasswordForm.controls['username'].value;
      password = this.changePasswordForm.controls['password'].value;

    } else {
      userName = this.loginForm.controls['username'].value;
      password = this.loginForm.controls['password'].value;
    }

    if (userName?.indexOf('-s') > -1) {
      userName = userName.replace('-s', '').trim();
      metaDataMode = true;
    }

    const loginCredentials: LoginCredential = {
      country_code: countryCode,
      language_name: languageName,
      region_name: regionName,
      username: userName,
      password,
      new_password: newPassword,
      meta_data_mode: metaDataMode,
      pitSessionTimeoutInMinutes: this.settings.pitSessionTimeoutInMinutes ? this.settings.pitSessionTimeoutInMinutes : 15,
      twoFactorCode
    };

    const oberversArray: Observable<any>[] = [];

    if (!localStorage.getItem('session')) {
      oberversArray.push(this.authService.login(loginCredentials).pipe(mergeMap((res: any) => {
        this.setSession(res);

        const body: SetAppIdRequest = {
          appId: this.settings.appIdSuffix ? PitNameEnum.PIT_FM_SMART + '_' +
            this.settings.appIdSuffix : PitNameEnum.PIT_FM_SMART
        };


        return this.sessionService.setAppId(body).pipe(mergeMap((res: boolean) => {
          let setUserRoleObs: Observable<any>;

          if (this.configService.getSettings().userRole) {
            const body: SetUserRoleRequest = {userRoleName: this.configService.getSettings().userRole};
            setUserRoleObs = this.userService.setUserRole(body);
          } else {
            setUserRoleObs = of([1]);
          }

          return setUserRoleObs.pipe(mergeMap(() => {
            if (this.settings.defaultUserRoleCallFunction && this.settings.defaultUserRoleCallFunction !== '') {
              const entityCallFunctionRequest: EntityCallFunctionRequest = {Functionname: this.settings.defaultUserRoleCallFunction};
              return this.userService.callUserRoleFunction(entityCallFunctionRequest).pipe(mergeMap((userRole: UserRole) => {
                const setUserRoleRequest: SetUserRoleRequest = {userRoleName: userRole.Name};
                return this.userService.setUserRole(setUserRoleRequest).pipe(mergeMap((result: boolean) => {
                  return this.authService.getUserRoleAndCheckPermissionsObs();
                }));
              }));
            } else {
              return this.authService.getUserRoleAndCheckPermissionsObs();
            }

          }));
        }));
      }), catchError((error: any) => {
        console.log(error);
        if (error.error.code === 'PasswordExpired') {
          this.changePasswordForm.setValue(
            {
              'username': userName,
              'password': password,
              'newPassword': '',
              'newPasswordConfirm': '',
            }
          );
          this.isPasswordExpired = true;
        }
        return error;
      })));
    } else {
      oberversArray.push(of([1]).pipe(mergeMap((res: any) => {
        const session: any = JSON.parse(localStorage.getItem('session'));

        const now: Date = new Date();
        const timeoutAt: Date = new Date(session['TimeoutAt']);


        if (timeoutAt.getTime() > now.getTime()) {
          return this.authService.getUserRoleAndCheckPermissionsObs();
        } else {
          AuthService.clearSessions(this.userService);
          return of(null);
        }

      }), catchError((error: any) => {

        console.log(error);
        return error;
      })));
    }

    from(oberversArray).pipe(mergeMap((obs: any) => {
      return obs;
    }), catchError((error: any) => {
      // window.alert("heyy");

      this.loaderService.hide();
      this.loaderService.isNavigating.next(false);

      AuthService.clearSessions(this.userService).subscribe();
      return error;
    }), finalize(() => {
      // if (res[0] && res[0] === '2FA') {
      //   const dialogRef = this.dialog.open(TwoFactorModalComponent, {
      //     width: '300px',
      //     height: '150px',
      //     disableClose: true
      //   });
      //
      //   dialogRef.afterClosed().subscribe((code: string) => {
      //     if (code) {
      //       this.login(code);
      //     } else {
      //       this.authService.clearSessions().subscribe();
      //     }
      //   });
      //
      //   return of(true);
      // }


    })).subscribe();


  }


  setSession(res: any) {
    localStorage.setItem('session', JSON.stringify(res));
    localStorage.setItem('sessionId', res['Id']);
    localStorage.setItem('user', res['Username']);
    localStorage.setItem('countryCode', res['countryCode'] ? res['countryCode'] : null);
  }
}
