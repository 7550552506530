import {Injectable} from '@angular/core';
import {ClientsService, EntityService, SessionService, UserService} from './swagger/services';
import {
  Client,
  EntityCallFunctionRequest,
  EntityData,
  EntityDescriptorResponse,
  GetActiveRequest,
  LoginCredential,
  SetClientArrayRequest,
  SetClientRequest,
  UserRole
} from './swagger/models';
import {from, Observable, of, Subscription, timer} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {Params, Router} from '@angular/router';
import {
  Action,
  ActivatorTypeEnum,
  Children,
  ConfigEntry,
  ConfigFileElement,
  Configuration,
  Dashboard,
  EntityDescriptorFlagsEnum,
  EntityObservers,
  FilterFile,
  Languages,
  LanguagesElement,
  Menu, Message,
  MessageEnum,
  MessageToast, MessageTypeEnum,
  TranslationFile,
  View,
  ViewEnum,
  Workflow,
  WorkflowView
} from '../../pit/structure';
import {HttpClient} from '@angular/common/http';
import {TranslationService} from './translation.service';
import {ConfigService} from './config.service';
import {PermissionService} from './permission.service';
import {RoutingProviderService} from '../../pit/services/routing-provider.service';
import {DataService} from './data.service';
import {NavigationElement, NavigationService} from './navigation.service';
import {MessageStackService} from './message-stack.service';
import {DashboardFilter} from '../models/DashboardFilter';
import {LoaderService} from './loader.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  defaultLang: LanguagesElement;

  languagesConfig: Languages;

  activeClient: Client;

  userRole: UserRole;

  observers: EntityObservers[] = [];


  poller: Observable<any>;

  dashboardRefreshObs: Observable<any>;

  pollerSubscription: Subscription;

  dashboardRefreshSubscription: Subscription;

  clients: Client[];

  constructor(private userService: UserService,
              private router: Router,
              private httpClient: HttpClient,
              private entityService: EntityService,
              private configService: ConfigService,
              private permissionService: PermissionService,
              private translationService: TranslationService,
              private clientService: ClientsService,
              private sessionService: SessionService,
              private dataService: DataService,
              private routingProviderService: RoutingProviderService,
              private messageStackService: MessageStackService,
              private loaderService: LoaderService,
              private navigationService: NavigationService) {


    if (localStorage.getItem('userRole') && localStorage.getItem('userRole') !== '') {
      this.setUserRole(JSON.parse(localStorage.getItem('userRole')));


    }

    if (localStorage.getItem('sessionId') !== null && localStorage.getItem('user') && localStorage.getItem('userRole') !== '') {

      this.poller = timer(this.configService.getSettings().pollerInterval ? this.configService.getSettings().pollerInterval : 60000, this.configService.getSettings().pollerInterval ? this.configService.getSettings().pollerInterval : 60000).pipe(mergeMap((res: any) => {
        return this.sessionService.getSession();
      }));

      if (this.configService.getSettings().poller) {
        this.sessionService.getSession().subscribe();
        console.log("Polling gestartet");
        this.pollerSubscription = this.poller.subscribe();
      }

      this.sessionService.getSession().pipe(map((res: any) => {
        if (res === false) {
          localStorage.removeItem('sessionId');
          localStorage.removeItem('user');
          localStorage.removeItem('countryCode');
          return null;
        }

        this.clientService.getActiveClients().subscribe((clients: any) => {
          const client: Client = clients && clients['Client'].length > 0 ? clients['Client'][0] : null;
          this.activeClient = client;
        });

        this.clientService.getAvailableClients().subscribe((clients: any) => {
          this.clients = clients['Client'];

        });
      })).subscribe();
    }


  }

  setDefaultLang(defaultLang: LanguagesElement, reload: boolean = false): void {
    this.defaultLang = defaultLang;
    const date: Date = new Date();
    const config: any = {};

    const i18nFolder: string = 'assets/config/' + localStorage.getItem('i18nFolder');

    const languageFiles: ConfigFileElement[] = [
      {
        name: 'currentLanguage',
        path: i18nFolder + '/' + defaultLang.countryCode.toLowerCase() + '.json?time=' + date.getTime()
      },
      {
        name: 'app',
        path: 'assets/i18n/' + defaultLang.countryCode.toLowerCase() + '-app.json?time=' + date.getTime()
      },
      {
        name: 'error',
        path: 'assets/i18n/' + defaultLang.countryCode.toLowerCase() + '-error.json?time=' + date.getTime()
      },
      {
        name: 'langScheduler',
        path: 'assets/i18n/scheduler/' + defaultLang.countryCode.toLowerCase() + '.json?time=' + date.getTime()
      },];

    localStorage.setItem('defaultLang', JSON.stringify(defaultLang));

    from(languageFiles).pipe(mergeMap((res: any, index: number) => {
        return this.httpClient.get<TranslationFile>(res.path);
      }), map((result, index) => {
        Object.assign(config, result);
        const element: ConfigFileElement = languageFiles[index];
        return {config: result, index};
      })
    ).subscribe((res: any) => {
    }, error => {
    }, () => {
      this.translationService.translation = config;
      localStorage.setItem('currentLanguage', JSON.stringify(config));
      if (reload) {
        window.location.reload();
      }
    });

    // this.httpClient.get<TranslationFile>('assets/i18n/' + defaultLang.countryCode.toLowerCase() + '.json?time=' + date.getTime()).subscribe((res: TranslationFile) => {
    //   this.translationService.translation = res;
    //   localStorage.setItem('defaultLang', JSON.stringify(defaultLang));
    //   localStorage.setItem('currentLanguage', JSON.stringify(res));
    //   if (reload) {
    //     window.location.reload();
    //   }
    //
    // });


  }

  setLanguagesConfig(languagesConfig: Languages): void {
    this.languagesConfig = languagesConfig;
    localStorage.setItem('languagesConfig', JSON.stringify(languagesConfig));
  }


  getLanguages(): Observable<Languages> {
    const date: Date = new Date();
    return this.httpClient.get<Languages>('assets/config/languages.json?time=' + date.getTime());
  }

  login(formData: LoginCredential): Observable<string[]> {
    // In Milliseconds
    this.poller = timer(this.configService.getSettings().pollerInterval ? this.configService.getSettings().pollerInterval : 60000, this.configService.getSettings().pollerInterval ? this.configService.getSettings().pollerInterval : 60000).pipe(mergeMap((res: any) => {
      return this.sessionService.getSession();
    }));

    const config: Configuration = this.configService.getConfiguration();
    // const dashboard: Dashboard = config.dashboards['dashboard'];

    return this.userService.loginUser(formData).pipe(map(res => {
      if (this.configService.getSettings().poller) {
          this.pollerSubscription = this.poller.subscribe();
      }
      return res;

    }));
  }

  startDashboardRefreshSubscription(dashboard: Dashboard, dashboardFilter: DashboardFilter): void {
    const interval: number = dashboard.settings.refreshInterval;

    dashboardFilter = Object.assign(dashboardFilter, {dashboardTime: new Date().toTimeString()});
    this.dashboardRefreshObs = timer(interval).pipe(map((res: any) => {
      const navigationElement: NavigationElement = {
        urlArr: ['dashboard', dashboard.name],
        dashboardFilter
      };
      this.router.navigate(navigationElement.urlArr, {queryParams: navigationElement.dashboardFilter});
    }));

    this.dashboardRefreshSubscription = this.dashboardRefreshObs.subscribe();
  }

  logout(): Observable<any> {
    return this.userService.logoutUser().pipe(map((res: any) => {
      this.router.navigate(['login']);
      if (this.configService.getSettings().poller) {
        this.pollerSubscription.unsubscribe();
      }

    }));
  }

  deactivate(config: Configuration): Observable<EntityObservers> {
    // const observers: EntityObservers[] = [];
    const viewResult: View[] = [];

    config.workflows.forEach((workflow: Workflow) => {
      for (const viewKey in workflow.views) {
        const view: View = workflow.views[viewKey];

        const foundView: View = viewResult.find(r => r.name === workflow.name + ':' + view.name);
        if (!foundView) {
          viewResult.push(view);
        } else {
          continue;
        }

        if (view.binding) {
          const observe: Observable<any> = this.entityService.getEntityDescriptor(view.binding).pipe(map((res: EntityDescriptorResponse) => {
            return res;
          }));

          if (!this.observers.find(r => r.binding === view.binding)) {
            this.observers.push({observe, binding: view.binding, result: {}});
          }

        }

      }
    });


    const observeClient: Observable<any> = this.clientService.getWritableClients().pipe(map((client: Client) => {
      this.activeClient = client;
      localStorage.setItem('activeClient', JSON.stringify(this.activeClient));
      return client;
    }));

    this.observers.push({observe: observeClient, binding: '', result: {}});

    const observeActiveClient: Observable<any> = this.clientService.getActiveClients().pipe(map((clients: any) => {
      this.activeClient = clients && clients['Client'].length > 0 ? clients['Client'][0] : null;
      return this.activeClient;
    }));

    this.observers.push({observe: observeActiveClient, binding: '', result: {}});

    return from(this.observers).pipe(mergeMap((res: EntityObservers) => {
      return res.observe.pipe(map((result: EntityDescriptorResponse) => {
        res.result = result;
        return res;
      }));
    })).pipe(map(res => {
      return res;
    }));
  }

  setPermissions(configuration: Configuration, entityObservers: EntityObservers[]): void {
    let children: Children;
    let action: Action;
    let viewX: View;


    configuration.workflows.forEach((workflowX: Workflow) => {
      for (let viewKey in workflowX.views) {
        viewX = workflowX.views[viewKey];
        const entityObserve: EntityObservers = entityObservers.find(r => r.binding === viewX.binding);

        if (entityObserve) {
          const permission: EntityDescriptorFlagsEnum = this.permissionService.getEntityDescriptorFlags(entityObserve.result.Flags);
          viewX.EntityPermission = permission;
        }


        if (viewX.type === ViewEnum.LIST && viewX.filters && viewX.filters.length > 0 && !viewX.columns) {
          viewX.columns = [];

        }

      }
    });

    this.setConfig(configuration);
  }

  setConfig(config: Configuration): void {
    this.configService.configuration = config;
    localStorage.setItem('config', JSON.stringify(config));
  }

  getPermissionObservables(): Observable<any> {
    return this.deactivate(this.configService.getConfiguration()).pipe(catchError((error: any) => {
      return error;
    }));
  }

  getUserRole(): UserRole {
    return this.userRole;
  }

  setUserRole(userRole: UserRole): void {
    this.userRole = userRole;
    localStorage.setItem('userRole', JSON.stringify(userRole));
  }

  checkMenuPermission(userRoles: string[], activeUserRole: UserRole): string {
    return userRoles.find(r => r === activeUserRole.Name);
  }

  checkMenuClientsPermission(allowedClients: string[], activeClient: Client): string {
    return allowedClients.find(r => r === activeClient.Name);
  }

  getUserRoleAndCheckPermissionsObs(): Observable<any> {
    this.loaderService.isNavigating.next(true);
    const params: Params = this.routingProviderService.getInitQueryParams();
    const getActiveRequest: GetActiveRequest = {userRoleAlternative: this.configService.getSettings().userRoleAlternative};
    let setActiveScopeObs: Observable<any>;
    let setActiveClientObs: Observable<any>;

    if (params && params['client']) {
      setActiveClientObs = this.setActiveClient(params['client']);
    } else {
      setActiveClientObs = of([1]);
    }

    if (params && params['scope']) {
      const setClientArrayRequest: SetClientArrayRequest = {ClientNumbers: [params['scope']]};
      setActiveScopeObs = this.clientService.setActiveScope(setClientArrayRequest);
    } else {
      setActiveScopeObs = of([1]);
    }

    return setActiveClientObs.pipe(mergeMap(() => {
      return setActiveScopeObs.pipe(mergeMap(() => {
        return this.userService.getUserRole(getActiveRequest).pipe(mergeMap((userRole: UserRole) => {
          this.setUserRole(userRole);

          if (this.configService.getSettings().defaultClientCallFunction && this.configService.getSettings().defaultClientCallFunction !== '') {
            const entityCallFunctionRequest: EntityCallFunctionRequest = {Functionname: this.configService.getSettings().defaultClientCallFunction};
            this.entityService.entityCallFunction(entityCallFunctionRequest).subscribe();
          }


          return this.sessionService.getCurrentUserEntityData().pipe(mergeMap((entityData: EntityData) => {
            localStorage.setItem('currentUserEntityData', JSON.stringify(entityData));

            return this.clientService.getAvailableClients().pipe(mergeMap((result: any) => {
              this.clients = result.Client;


              return this.clientService.getActiveClients().pipe(map((clients: any) => {
                const client: Client = clients && clients['Client'].length > 0 ? clients['Client'][0] : null;
                this.activeClient = client;

                const configuration: Configuration = this.getPermittedConfig(userRole, this.activeClient);
                const configEntry: ConfigEntry = this.configService.configFiles.find(r => r.name === 'config');
                configEntry.config = configuration;
                localStorage.setItem(configEntry.name, JSON.stringify(configEntry.config));


                if (params && params['view']) {
                  const params: Params = this.routingProviderService.getInitQueryParams();

                  const workflowView: WorkflowView = this.dataService.formDataService.getWorkFlowAndViewFromEndpoint(params['view']);

                  if (workflowView.view.tabsAttribute) {
                    // VirtualTabs DeepLink
                    this.navigationService.generateVirtualTabs(params['view'], params['object'],
                      this.configService.getConfiguration()).subscribe((navigationElement: NavigationElement) => {
                      this.routingProviderService.setInitQueryParams(null);
                      this.router.navigate(navigationElement.urlArr, {queryParams: navigationElement.dashboardFilter}).then(() => {
                        this.loaderService.isNavigating.next(false);
                      });
                      return;
                    });

                  } else {
                    const workflowView: WorkflowView = this.dataService.formDataService.getWorkFlowAndViewFromEndpoint(params['view']);

                    const navigationElement: NavigationElement = NavigationService.getRouteFromEndpoint(
                      workflowView.workflow.name + ':' + workflowView.view.name,
                      this.configService.getConfiguration(), params['object'],
                      workflowView.tabWorkflowView ? workflowView.tabWorkflowView.view : null,
                      null,
                      null,
                      workflowView.workflow);

                    this.router.navigate(navigationElement.urlArr, {queryParams: navigationElement.dashboardFilter}).then(() => {
                      this.loaderService.isNavigating.next(false);
                      this.routingProviderService.setInitQueryParams(null);
                    });
                  }
                } else {
                  this.navigateToFirstAction(userRole);
                }
              }));
            }));
          }));
        }));
      }));
    }));
  }

  navigateToFirstAction(userRole: UserRole): void {
    let endpoint: string;
    let action: Action;
    let workFlowView: WorkflowView;
    let navigationElement: NavigationElement;
    let filterFile: FilterFile;

    // find first View
    action = this.dataService.navigationService.getFirstAction(this.configService.getConfiguration().menus.main, this.getUserRole(), this.activeClient);
    if (action) {
      endpoint = action.endpoint;
      if (action.mode !== 'dashboard') {
        workFlowView = this.dataService.formDataService.getWorkFlowAndViewFromEndpoint(endpoint);
      }
    }

    const defaultRouteEndpoint: string = (this.configService.getSettings().default_dashboard_after_login
      && this.configService.getSettings().default_dashboard_after_login !== '') ?
      this.configService.getSettings().default_dashboard_after_login : (this.configService.getSettings().default_route_after_login
        && this.configService.getSettings().default_route_after_login !== '') ?
        this.configService.getSettings().default_route_after_login : null;


    if (defaultRouteEndpoint) {
      const menu: Menu = this.navigationService.getMenuByEndpoint(this.configService.getConfiguration().menus.main, defaultRouteEndpoint);
      // Default Dashboard
      if (menu && menu.action && menu.action.mode && menu.action.mode === ActivatorTypeEnum.DASHBOARD) {
        this.routingProviderService.setInitialRoute({urlArr: ['dashboard', endpoint], dashboardFilter: null});
        this.router.navigate(['dashboard', endpoint]).then(() => {
          this.loaderService.isNavigating.next(false);
        });
        return;
      }

      // Default Route List
      if (menu && menu.action || (menu && menu.action && !menu.action.mode || menu && menu.action.mode !== ActivatorTypeEnum.DASHBOARD)
        && this.navigationService.isMenuPermitted(menu, userRole, this.activeClient)) {
        action = null;
        endpoint = menu.action.endpoint;
        workFlowView = this.dataService.formDataService.getWorkFlowAndViewFromEndpoint(endpoint);
        navigationElement =
          NavigationService.getRouteFromEndpoint(endpoint, this.configService.getConfiguration(), null);
      }
    }

    if (action || workFlowView) {
      if (action) {
        if (action.mode === ActivatorTypeEnum.DASHBOARD) {
          this.routingProviderService.setActiveSidebarMenuName(action.endpoint, this.configService.getConfiguration());
          const dashboardFilter: DashboardFilter = {time: new Date().toTimeString()};
          navigationElement = {urlArr: ['dashboard', action.endpoint], dashboardFilter};
        } else {
          if (action.defaultFilter) {
            filterFile = action.filters.find(r => r.name === action.defaultFilter);
          }
          navigationElement = NavigationService.getRouteFromEndpoint(action.endpoint,
            this.configService.getConfiguration(), null, null, filterFile);
        }
      }

      if (workFlowView && workFlowView.view && workFlowView.view.type === ViewEnum.WIZARD) {
        const actionX: Action = {endpoint};

        this.dataService.formDataService.createEntityObs(actionX, null, this.dataService, this.dataService.callbackService,
          this.dataService.navigationService, this.router).subscribe(res => {
          this.routingProviderService.setInitialRoute({urlArr: res.urlArr, dashboardFilter: res.dashboardFilter});
          this.router.navigate(res.urlArr, res.dashboardFilter ?
            {queryParams: res.dashboardFilter} : {}).then(() => {
            this.loaderService.isNavigating.next(false);
          });
        });
      } else {
        this.router.navigate(['system', 'client_change']).then(() => {
          this.routingProviderService.setInitialRoute(navigationElement);
          this.routingProviderService.lastUrl = navigationElement.urlArr.join('/');
          this.router.navigate(navigationElement.urlArr, {queryParams: navigationElement.dashboardFilter}).then(() => {
            this.loaderService.isNavigating.next(false);
          });
        });

      }

    } else {
      const message: Message = {
        title: this.translationService.getTranslation('login', 'initial_route_title'),
        text: this.translationService.getTranslation('login', 'initial_route_text'),
        type: MessageTypeEnum.NOTICE
      };
      const originalConfig: Configuration = this.configService.getOriginalConfig();
      this.configService.setConfiguration(originalConfig);
      this.messageStackService.addMessage(message);
      this.loaderService.isNavigating.next(false);
      AuthService.clearSessions(this.userService);
    }
  }

  getPermittedConfig(userRole: UserRole, activeClient: Client): Configuration {

    const configuration: Configuration = this.configService.getConfiguration();

    configuration.menus.main = this.getPermittedMenus(configuration.menus.main, userRole, activeClient);

    if (configuration.menus.tiles) {
      configuration.menus.tiles = this.getPermittedMenus(configuration.menus.tiles, userRole, activeClient);
    }

    return configuration;
  }

  getPermittedMenus(menuElements: Menu[], userRole: UserRole, activeClient: Client): Menu[] {
    const menus: Menu[] = [];
    menuElements.forEach((menu: Menu) => {
      if ((!menu.userRoles || this.checkMenuPermission(menu.userRoles, userRole)) &&
        (!menu.allowedClients || this.checkMenuClientsPermission(menu.allowedClients, activeClient))) {
        menus.push(menu);

        if (menu.children) {
          const children: Children[] = [];
          menu.children.forEach((child: Children) => {
            if (!child.userRoles || this.checkMenuPermission(child.userRoles, userRole) &&
              (!child.allowedClients || this.checkMenuClientsPermission(child.allowedClients, activeClient))) {
              children.push(child);
            }
          });

          menu.children = children;
        }
      }
    });

    return menus;
  }

  setActiveClient(clientNumber: number): Observable<any> {
    const setClientArrayRequest: SetClientArrayRequest = {ClientNumbers: [clientNumber]};
    const setClientRequest: SetClientRequest = {ClientNumber: clientNumber};
    return this.clientService.setActive(setClientArrayRequest).pipe(mergeMap((setActiveRes: any) => {
      return this.clientService.setWriteable(setClientRequest).pipe(mergeMap((setActiveResult: any) => {
        return of(true);
      }));
    }));
  }

  static clearSessions(userService: UserService): Observable<any> {
    localStorage.removeItem('session');
    localStorage.removeItem('sessionId');
    localStorage.removeItem('user');
    localStorage.removeItem('countryCode');
    return userService.clearSession();
  }



}
